import { ReactNode } from 'react';

import { theme } from './theme';

export interface SectionHeaderProps {
  children: ReactNode;
  color?: 'textBlack' | 'gray' | 'disabledGray';
  weight?: 'medium' | 'regular';
}

export const SectionHeader = ({
  children,
  color = 'textBlack',
  weight = 'medium',
}: SectionHeaderProps) => {
  return (
    <span
      className="hlx-typography-section-heading"
      style={{
        color: COLOR_STYLE_MAP[color],
        // @ts-ignore
        '--font-weight': weight === 'medium' ? '700' : '400',
      }}
    >
      {children}
    </span>
  );
};

const COLOR_STYLE_MAP: Record<
  NonNullable<SectionHeaderProps['color']>,
  string
> = {
  disabledGray: theme.color.system.disabledGray,
  textBlack: theme.color.system.textBlack,
  gray: theme.color.system.gray,
};
